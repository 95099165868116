import { State } from '@arteli/state'
import { random } from '@arteli/utils'

type SnackType = 'success' | 'error' | 'warning' | 'info'

type Snack = {
  id?: string | number
  message: string
  timeout?: number
  type: SnackType
}

const DEFAULT_TIMEOUT = 6e3

type Notifications = { snacks: Snack[] }

export const notifications = new State<Notifications>({
  snacks: []
})

export const addSnack = (snack: Snack) => {
  snack.id = snack.id ?? random.String
  snack.timeout = snack.timeout || DEFAULT_TIMEOUT

  notifications.set('snacks', [...notifications.get('snacks'), snack])

  if (snack.timeout) {
    setTimeout(() => removeSnack(snack.id), snack.timeout)
  }
}

export const toast = {
  Success: (message: string, timeout?: number) => addSnack({ message, type: 'success', timeout }),
  Info: (message: string, timeout?: number) => addSnack({ message, type: 'info', timeout }),
  Warning: (message: string, timeout?: number) => addSnack({ message, type: 'warning', timeout }),
  Error: (message: string, timeout?: number) => addSnack({ message, type: 'error', timeout })
}

export const removeSnack = (id: Snack['id']) => {
  notifications.set(
    'snacks',
    notifications.get('snacks').filter((snack) => snack.id !== id)
  )
}
